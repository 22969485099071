/* eslint-disable no-unused-vars */
import {RequestHelper} from "@/entities/helpers/RequestHelper";
import ltrim from "locutus/php/strings/ltrim";
import cloneDeep from "lodash/cloneDeep";

export class Ocr {
	constructor() {
		const that = this;

		that.requestHelper = new RequestHelper(that);
	}

	/**
	 *
	 * @param dataURL
	 * @returns {Promise<{ocrResultRowId: (number|default.methods.ocr_result_row_id), ocrText: Promise<{textFound: string, artikelNummer: string, model: string, versieNummer: string, textFoundLines: string, serieNummer: string}>}>}
	 */
	async doOCR(dataURL) {
		const that = this;

		console.log('dataURL', dataURL.length);

		const ocrTextRaw = await this._getOCRTextRawATAGMS(dataURL);

		const ocrResultRowId = ocrTextRaw.ocr_result_row_id;
		const ocrText        = this.getOCRTextFromRaw(ocrTextRaw);

		this._sendResultMsg(ocrResultRowId, ocrText).then(() => {});

		return {ocrResultRowId, ocrText};
	}

	/**
	 *
	 * @param dataURL
	 * @returns {Promise<any>}
	 * @private
	 */
	async _getOCRTextRawATAGMS(dataURL) {
		const that = this;

		const bodyData = {
			data_url: dataURL
		}

		return await that.requestHelper.queryPost(
			bodyData, 'ocr/check'
		);
	}

	/**
	 *
	 * @param ocrResultRowId
	 * @param ocrText
	 * @returns {Promise<void>}
	 */
	async _sendResultMsg(ocrResultRowId, ocrText) {
		const that = this;

		if (ocrText !== undefined) {
			let ocrTextCleaned = cloneDeep(ocrText);
			delete ocrTextCleaned.textFound;
			delete ocrTextCleaned.textFoundLinesArrayArray;

			ocrTextCleaned.ocr_result_msg   = ltrim(ocrText.textFound.textFoundLines.trim(), '<br />').trim();
			ocrTextCleaned.ocr_result_array = ocrText.textFoundLinesArrayArray;

			let reqData = {
				ocr_result_row_id : ocrResultRowId,
				ocr_text_collected: ocrTextCleaned
			};

			return await that.requestHelper.queryPost(
				reqData,
				'ocr/result_msg'
			);
		}
	}

	/**
	 *
	 * @param ocrTextRaw
	 * @returns {Promise<{textFound: string, artikelNummer: string, model: string, versieNummer: string, textFoundLines: string, serieNummer: string}>}
	 */
	getOCRTextFromRaw(ocrTextRaw) {
		const that = this;

		let textFound                = "";
		let textFoundLines           = "";
		let textFoundLinesB          = "";
		let textFoundLinesArray      = [];
		let textFoundLinesArrayArray = [];
		let model                    = "";
		let artikelNummer            = "";
		let versieNummer             = "";
		let serieNummer              = "";

		const modelSubstrings         = [
			'Model no.:', 'MODEL NO,', 'Model no.', 'Model.nr:',
			'MODEL NO.:', 'MODEL NO.', 'odel no.:',
			'Mod. no.', 'Mod no.', 'Mod. no', 'Mod no',
			'MODEL:', 'Model:', 'Model :', 'Mode/', 'Model',
		]
		const artikelNummerSubstrings = [
			'ART. NO..', 'ART. NO.:', 'Art. no.', 'Art-no.:', 'ART. NO-',
			'Art no.:', 'ART. NO', 'ART, no.', 'ART, No.', 'Artno..',
			'ART, NO.', 'ART. no.:', 'ART. No.:', 'Artno:', 'An.no.',
			'Artno-', 'ART.', 'AN. no.', 'Alt. no.', 'Art. no.', 'Art.no.',
			'rt.no.:', 'Ad. no.', 'ART. NO.:', 'Art-no.', 'Artno.:',
			'art no.', 'Art'

		]
		const serieNummerSubstrings   = [
			'SERIAL NO.:', 'serie nr.:', 'Serie nr.:', 'Serial-Nr.',
			'Serial no. ', 'Senal no', 'Send no:', 'Serial no.:',
			'SERA-NO:', 'SERIAL NO', 'Serie nr.', 'serie nr.', 'SER!AL',
			'SER. NO:', 'S-N0', 's-No', 's-NO', 'S-No'
		]

		let lineString  = "";
		let lineStringB = "";
		let lineString2 = "";
		let lineString3 = [];
		if (ocrTextRaw !== undefined) {
			// round one - get textFoundLinesArray
			for (const region of ocrTextRaw.regions) {
				for (const line of region.lines) {
					lineString2 = "";
					lineString3 = [];
					for (const word of line.words) {
						lineString2 = lineString2 + word.text + ' ';
						lineString3.push(word.text);
					}
					textFoundLinesArray.push(lineString2);
					textFoundLinesArrayArray.push(lineString3);
				}
			}

			let lineIndex = 0;
			for (const region of ocrTextRaw.regions) {

				for (const line of region.lines) {
					lineString  = "<br />⬤&nbsp; ";
					lineStringB = "## ";
					lineString2 = "";

					textFound = textFound + '<br /><br />';
					for (const word of line.words) {
						textFound   = textFound + word.text + ' &nbsp;&nbsp;.&nbsp;&nbsp; ';
						lineString  = lineString + word.text + ' &nbsp;&nbsp;.&nbsp;&nbsp; ';
						lineStringB = lineStringB + word.text + ' ';
						lineString2 =
							// '[' + lineIndex + '] ' +
							lineString2 + word.text + ' ';
					}
					textFoundLines  = textFoundLines + lineString;
					textFoundLinesB = textFoundLinesB + lineStringB + ' -- ';

					////////////////////////

					if (new RegExp(modelSubstrings.join("|")).test(lineString2)) {
						const __ret = that._modelNum(model, lineString2, modelSubstrings, textFoundLinesArray, lineIndex);
						model       = __ret.model;
					}
					else if (new RegExp(artikelNummerSubstrings.join("|")).test(lineString2)) {
						const __ret   = that._artNum(artikelNummer, lineString2, artikelNummerSubstrings, textFoundLinesArray, lineIndex);
						artikelNummer = __ret.artikelNummer;
						if (versieNummer === "") {
							versieNummer = __ret.versieNummer;
						}
					}
					else if (new RegExp(serieNummerSubstrings.join("|")).test(lineString2)) {
						const __ret = that._serialNum(serieNummer, lineString2, serieNummerSubstrings, textFoundLinesArray, lineIndex);
						serieNummer = __ret.serieNummer;
					}

					// # reduce further
					if (model === "") {
						// XX000.. search format
						const ch2AlphaString = lineString2.substring(0, 2).replace(/[^a-z]/gi, '');
						if (ch2AlphaString.trim().length === 2 && ch2AlphaString !== "") {
							const ch3NumString = lineString2.substring(2, 5).replace(/[^0-9]/gi, '');
							if (ch3NumString.trim().length === 3 && ch3NumString !== "") {
								model = String(lineString2.trim());
								model = that._modelCleanup(model);
							}
						}
					}
					if (artikelNummer === "") {
						// 729352/01 search format
						const ch6NumString = lineString2.substring(0, 6).replace(/[^0-9]/gi, '');
						if (ch6NumString.trim().length === 6 && lineString2.substring(6, 7) === '/') {
							// todo _artikelNummerCleanup

							let artikelNummerTemp   = "";
							let versieNummerTemp   = "";

							let artikelNummerParts = lineString2.split("/");
							if (artikelNummerParts !== undefined) {
								if (artikelNummerParts[0] !== undefined) {
									artikelNummerTemp = artikelNummerParts[0];
								}
								if (artikelNummerParts[1] !== undefined) {
									versieNummerTemp = artikelNummerParts[1];
								}
							}

							// remove all non-numeric
							artikelNummerTemp = artikelNummerTemp.replace(/\D/g, '');

							if (artikelNummerTemp.length < 6) {
								artikelNummerTemp = "";
							}

							if (artikelNummerTemp !== "") {
								artikelNummer = artikelNummerTemp;
							}

							// remove all non-numeric
							versieNummerTemp = versieNummerTemp.replace(/\D/g, '');

							if (versieNummerTemp.length < 2) {
								versieNummerTemp = "";
							}

							if (versieNummerTemp !== "") {
								versieNummer = versieNummerTemp;
							}
						}
					}
					if (serieNummer === "" && model !== "") {
						if (lineString2.trim().length === 12) {
							const ch2NumString = lineString2.substring(0, 2).replace(/[^0-9]/gi, '');
							if (ch2NumString.trim().length === 2 && ch2NumString !== "") {
								if (lineString2.substring(2, 3) === '.') {
									serieNummer = String(lineString2.trim());
								}
							}
						}
					}
					if (versieNummer === "" && model !== "" && model.includes('/')) {
						// break on '/'
						let modelParts = model.split("/");
						if (modelParts !== undefined) {
							if (modelParts[0] !== undefined) {
								model = modelParts[0];
							}
							if (modelParts[1] !== undefined) {
								versieNummer = modelParts[1];

								if (versieNummer.includes(' ')) {
									// break on ' '
									let versieNummerParts = versieNummer.split(" ");
									if (versieNummerParts[0] !== undefined) {
										versieNummer = versieNummerParts[0];
									}
								}
							}
						}

						// remove all non-numeric
						versieNummer = versieNummer.replace(/\D/g, '');
					}

					lineIndex++;
				}
			}
		}

		return {
			textFound: {
				textFound,
				textFoundLines,
				textFoundLinesB,
				textFoundLinesArray,
				textFoundLinesArrayArray
			},
			model,
			artikelNummer,
			versieNummer,
			serieNummer,
			textFoundLinesArrayArray
		};
	}

	/**
	 *
	 * @param model
	 * @returns {string}
	 * @private
	 */
	_modelCleanup(model) {
		// only use first part
		if (model.includes(' ')) {
			let modelParts = model.split(" ");
			if (modelParts[0] !== undefined) {
				model = modelParts[0];
			}
		}

		if (model.substring(2, 3) === 'f') {
			const cleanStart = 2;
			const cleanEnd   = 3;
			const cleanWhat  = '1';
			model            = model.substring(0, cleanStart) + cleanWhat + model.substring(cleanEnd);
		}
		if (model.substring(3, 4) === 'f') {
			const cleanStart = 3;
			const cleanEnd   = 4;
			const cleanWhat  = '1';
			model            = model.substring(0, cleanStart) + cleanWhat + model.substring(cleanEnd);
		}
		if (model.substring(4, 5) === 'f') {
			const cleanStart = 4;
			const cleanEnd   = 5;
			const cleanWhat  = '1';
			model            = model.substring(0, cleanStart) + cleanWhat + model.substring(cleanEnd);
		}

		return model;
	}

	/**
	 *
	 * @param model
	 * @param lineString2
	 * @param modelSubstrings
	 * @param textFoundLinesArray
	 * @param lineIndex
	 * @returns {{model: string}}
	 * @private
	 */
	_modelNum(model, lineString2, modelSubstrings, textFoundLinesArray, lineIndex) {
		const that = this;

		// model = lineString2;

		for (const substring of modelSubstrings) {
			if (lineString2.toLowerCase().includes(substring.toLowerCase())) {
				lineString2 = lineString2.substring(
					lineString2.toLowerCase().indexOf(substring.toLowerCase()) +
					substring.length
				);
			}
		}

		model = String(lineString2.trim());
		model = that._modelCleanup(model);

		return {model};
	}

	/**
	 *
	 * @param artikelNummer
	 * @param lineString2
	 * @param artikelNummerSubstrings
	 * @param textFoundLinesArray
	 * @param lineIndex
	 * @returns {{artikelNummer: string, versieNummer: string}}
	 * @private
	 */
	_artNum(artikelNummer, lineString2, artikelNummerSubstrings, textFoundLinesArray, lineIndex) {
		const that = this;

		let versieNummer = "";

		for (const substring of artikelNummerSubstrings) {
			if (lineString2.toLowerCase().includes(substring.toLowerCase())) {
				lineString2 = lineString2.substring(
					lineString2.toLowerCase().indexOf(substring.toLowerCase()) +
					substring.length
				);
			}
		}

		artikelNummer = String(lineString2.trim());
		// # remove 'ATAG'
		artikelNummer = artikelNummer.replace('ATAG', '').trim();

		// if 7th char is a '1'
		// change that char to '/'
		if (artikelNummer.charAt(6) === '1') {
			let replacer          = '/';
			let artikelNummerBack = replacer.concat(artikelNummer.slice(7));

			artikelNummer = artikelNummer.substring(0, 6) + artikelNummerBack;
		}

		// todo _artikelNummerCleanup

		if (artikelNummer.includes('/')) {
			// break on '/'
			let artikelNummerParts = artikelNummer.split("/");
			if (artikelNummerParts !== undefined) {
				if (artikelNummerParts[0] !== undefined) {
					artikelNummer = artikelNummerParts[0];
				}
				if (artikelNummerParts[1] !== undefined) {
					versieNummer = artikelNummerParts[1];
				}
			}
		}
		else {
			// break on space
			let artikelNummerParts = artikelNummer.split(" ");
			if (artikelNummerParts !== undefined) {
				if (artikelNummerParts[0] !== undefined) {
					artikelNummer = artikelNummerParts[0];
				}
				if (artikelNummerParts[1] !== undefined) {
					if (artikelNummerParts[1].length === 2) {
						versieNummer = artikelNummerParts[1];
					}
					else {
						versieNummer = artikelNummerParts[1].substring(1);
					}
				}
			}
		}

		// remove all non-numeric
		artikelNummer = artikelNummer.replace(/\D/g, '');

		if (artikelNummer.length < 6) {
			artikelNummer = "";
		}

		// remove all non-numeric
		versieNummer = versieNummer.replace(/\D/g, '');

		if (versieNummer.length < 2) {
			versieNummer = "";
		}

		return {artikelNummer, versieNummer};
	}

	/**
	 *
	 * @param serieNummer
	 * @param lineString2
	 * @param serieNummerSubstrings
	 * @param textFoundLinesArray
	 * @param lineIndex
	 * @returns {{serieNummer: string}}
	 * @private
	 */
	_serialNum(serieNummer, lineString2, serieNummerSubstrings, textFoundLinesArray, lineIndex) {
		const that = this;

		// serieNummer = lineString2;

		for (const substring of serieNummerSubstrings) {
			if (lineString2.toLowerCase().includes(substring.toLowerCase())) {
				lineString2 = lineString2.substring(
					lineString2.toLowerCase().indexOf(substring.toLowerCase()) +
					substring.length
				);
			}
		}

		serieNummer = String(lineString2.trim());

		// remove all non-numeric
		serieNummer = serieNummer.replace(/\D/g, '');

		return {serieNummer};
	}
}