/* eslint-disable no-unused-vars */
export class DataHelper {
	constructor() {

	}

	/**
	 *
	 * @param vueData
	 * @returns {any}
	 */
	getFlatData(vueData) {
		return JSON.parse(JSON.stringify(vueData));
	}

	/**
	 *
	 * @param name
	 * @param value
	 * @param days
	 */
	setCookie(name, value, days) {
		let expires = "";

		if (days) {
			let date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		}

		document.cookie = name + "=" + (value || "") + expires + "; path=/";
	}

	/**
	 *
	 * @param name
	 * @returns {string|null}
	 */
	getCookie(name) {
		let nameEQ = name + "=";
		let ca     = document.cookie.split(';');

		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1, c.length);
			}
			if (c.indexOf(nameEQ) === 0) {
				return c.substring(nameEQ.length, c.length);
			}
		}

		return null;
	}
}