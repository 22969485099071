/* eslint-disable no-unused-vars */
export class RequestHelper {
	/**
	 *
	 */
	constructor() {
		const that = this;

		this._base_url = process.env.VUE_APP_BASE_URL_SERVER_POC_LOCAL;
		if (process.env.NODE_ENV === process.env.VUE_APP_NODE_ENV_PROD) {
			this._base_url = process.env.VUE_APP_BASE_URL_SERVER_POC_PROD;
		}
		this._base_url_api = that.baseUrl + 'api/';

		// console.log('RequestHelper :: constructor', process.env.NODE_ENV, this._base_url_api);
	}

	/**
	 *
	 * @returns {string}
	 */
	get baseUrl() {
		return this._base_url;
	}

	/**
	 *
	 * @returns {string}
	 */
	get baseUrlApi() {
		return this._base_url_api;
	}

	/**
	 *
	 * @param bodyData
	 * @param cacheKey
	 * @param type
	 * @returns {Promise<any>}
	 */
	async queryPost(bodyData, cacheKey, type) {
		const that = this;

		if (type === undefined) {
			type = 'POST'
		}
		if (cacheKey === undefined) {
			console.error('Error - No cacheKey!!')
			return false;
		}

		// todo env
		const URL = that.baseUrlApi + cacheKey;

		let bodyDataFinal = {};
		if (bodyData !== undefined) {
			bodyDataFinal = bodyData;
		}
		// todo of alleen controleren op het hoofddomein
		bodyDataFinal.auth_hash = 'CXaPt6gGzUzCc2pAQCXaPt6gGzUzCc2pAQZ2ds95';

		const requestOptions = {
			method : type,
			headers: {'Content-Type': 'application/json'},
			body   : JSON.stringify(bodyData)
		};

		// if (type === 'GET') {
		// 	delete requestOptions.body;
		// }

		const remoteResponseObj = await fetch(URL, requestOptions);

		return await remoteResponseObj.json();
	}

}